// Basically this is the component in the list view that represents an event.
<template>
    <div class="card event_container">
      <div class="image_container">
        <!-- <Carousel :value="event.images" :numVisible="1" v-if="event.images"/> -->
        <!-- <Image :src="event.images[0].url" alt="event_image" imageClass="image_renderer"
         :preview="true" v-if="event.images"/> -->
        <img :src="event.images[0].url" alt="event_image" imageClass="image_renderer"
         :preview="true" v-if="event.images" class="image_renderer">
        <!-- <img src="../assets/default.png" class="card-img-top image_renderer" alt="event_image" v-else> -->
        <Image class='default_img' :src="require('../assets/default.png',)"
         alt='event_imagex' width="350px" v-else :preview='true'/>
      </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <div class="event_description">
                        <h3 class="card-title" v-text="event.name"></h3>
                        <p class="card-text" v-html="event.description"></p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div v-if="property_fetcher.name === 'Tortuga Bay'" class="offset-md-9">
                        <!-- Button trigger modal -->
                        <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                          BOOK NOW
                        </button>

                        <!-- OTP Modal -->
                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">Collaborator Validation</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="otp_validation = false"></button>
                              </div>
                              <div class="modal-body" v-if="otp_validation">
                                <div class="form-group" style="text-align: left;">
                                  <div class="alert alert-success" role="alert">
                                    <h4 class="alert-heading">OTP Code Sent!</h4>
                                    <p>A verification code has been sent to the following email address <strong>{{ collaborator_email }}</strong>, please enter the code in the following input.</p>
                                  </div>
                                  <label for="otp_code" class="form-label">OTP Code</label>
                                  <input type="number" v-model="otp_code" class="form-control" id="otp_code">
                                  <small v-if="error_message" style="color: red;">{{ error_message }}</small>
                                </div>
                              </div>
                              <div class="modal-body" v-else>
                                <div class="form-group" style="text-align: left;">
                                  <label for="employee_code" class="form-label">Employee code</label>
                                  <input type="number" v-model="collaborator_code" class="form-control" id="employee_code">
                                  <small v-if="error_message" style="color: red;">{{ error_message }}</small>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary close" data-bs-dismiss="modal" @click="otp_validation = false">Close</button>
                                <button type="button" class="btn btn-success" v-if="otp_validation && !requestSent" @click="onValidateOTP">
                                  Send
                                </button>
                                <button type="button" class="btn btn-success" v-else-if="!requestSent" @click="onRequestOTP(event)">
                                  Send
                                </button>
                                <button class="btn btn-success disabled" v-if="requestSent">
                                  <div class="spinner-border spinner-border-sm" role="status">
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                    <div class="offset-md-9" v-else>
                        <GenericBtn title="BOOK NOW" @click="search_availability(event)" render_class="btn btn-success"/>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import GenericBtn from './Button.vue'
import {mapGetters, mapActions} from 'vuex';
import Carousel from 'primevue/carousel';
import Image from 'primevue/image';
export default {
  name: 'Activities',
  components: {
    GenericBtn,
    Carousel,
    Image
  },
  props: {
    event: {
      type:Object,
    },
  },
  data(){
        return{
          collaborator_code: '',
          error_message: null,
          otp_validation: false,
          otp_code: '',
          collaborator_email: '',
          eventProduct: {},
          requestSent: false,
        }
    },
  methods: {
    ...mapActions(['selectEvent','resetBookingInfo', 'sendOTPValidation', 'validateOTP']),
    search_availability (event) {
      const specificEventPrice = this.customEventConfiguration
      .find((eventData) => eventData.event_id === event.productId);

      if (event.defaultRates[0].price.amount === '0' || event.defaultRates[0].price.amount === 0) {
        event.defaultRates[0].price.amount = specificEventPrice.standard_price;
      }

      this.$store.dispatch('resetBookingInfo');
      this.selectEvent(this.event);
      this.$router.push({name:'event_schedule', params:{event_id:event.productId}});
    },
    onRequestOTP(eventProduct){
      this.eventProduct = eventProduct;
      this.error_message = null;

      if (this.collaborator_code === '') {
        this.error_message = 'Este campo es requerido.';
        return;
      }
      this.requestSent = true;

      this.$store.dispatch('sendOTPValidation', this.collaborator_code).then((response) => {
        if (response.status == 404) {
          this.error_message = response.data.error;
        } else if (!response.data.result.status){
          this.error_message = 'Ha occurido un error, favor intentarlo nuevamente.'
        }else {
          this.otp_validation = true;
          this.collaborator_email = response.data.result.response.accepted[0];
        }
        this.requestSent = false;
      });
    },
    onValidateOTP(){
      const collaboratorData = {
        'email': this.collaborator_email,
        'otp_code': this.otp_code
      };
      this.error_message = null;
      if (this.otp_code === '') {
        this.error_message = 'Este campo es requerido.';
        return;
      }
      this.requestSent = true;

      this.$store.dispatch('validateOTP', collaboratorData).then((response) => {
        if (response.data.result.response.validez == "Valido") {
          document.querySelector('.btn.btn-secondary.close').click();
          this.search_availability(this.eventProduct);
        } else if (response.data.result.status_code) {
          this.error_message = 'Ha occurido un error, favor intentarlo nuevamente.';
        } else {
          this.error_message = "El codigo ingresado es invalido.";
        }
        this.requestSent = false;
      });
    }
  },
  computed: mapGetters(['property_fetcher', 'customEventConfiguration']),
}
</script>
<style scoped>
  .p-image{
    max-height: 310px;
    max-width: 100%;
    border-radius: 6px;
  }
  .event_description{
      text-align: left;
  }
  .event_container {
	background-color: #fafafa;
	margin-bottom: 14px;
	padding: 10px 10px 10px 10px;
    /* max-height: 360px; */
}
.image_container{
    /* max-height: 495px; */
    max-height: 30em;
    overflow-y: scroll;
    display: flex;
    width:auto;
}
/* .image_renderer{
    max-height: 310px;
    max-width: 100%;
    border-radius: 6px;
} */
.default-img:img{
    width: 63em;
}
.image_renderer {
  width: 99%;
  height: auto;
	border-radius: 6px;
}
@media screen and (max-width:900px) {
  .image_renderer {
    max-width: 100%;
    height: auto;
    border-radius: 6px;
  }
}
</style>
